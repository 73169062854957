import * as traversal from '../../utilities/traversal';
import {fadeIn, fadeOut} from '../../utilities/helpers';

export class headNav {

    protected desktopWidth = 1280;
    protected minDesktopWidthQuery = window.matchMedia(`(min-width: ${this.desktopWidth}px)`);
    protected maxTabletLandscapeWidth = 1279;
    protected maxTabletLandscapeWidthQuery = window.matchMedia(`(max-width: ${this.maxTabletLandscapeWidth}px)`);
    protected background : HTMLElement = document.querySelector('.suggestion-background');


    toggleMobile() {
        const html = document.getElementsByTagName('html')[0];
        let toggleNav = document.querySelectorAll('.toggle-head-nav');
        let mainNav = document.querySelectorAll('.head-nav-lvl1');
        let toggleIcon = document.querySelectorAll('.toggle-head-nav i');

        toggleNav = [].slice.call(toggleNav);
        mainNav = [].slice.call(mainNav);
        toggleIcon = [].slice.call(toggleIcon);

        toggleNav?.forEach((item: HTMLElement) => {
            item.addEventListener('click', () => {
                event.preventDefault();
                if (toggleNav[0].classList.contains('_active')) {
                    if (typeof mainNav[0] !== 'undefined') {
                        mainNav[0].setAttribute('style', 'display: none;');
                    }
                    if (typeof toggleIcon[0] !== 'undefined') {
                        toggleIcon[0].classList.remove('uil-times')
                        toggleIcon[0].classList.add('uil-bars')
                    }
                    if (typeof toggleNav[0] !== 'undefined') {
                        toggleNav[0].classList.remove('_active');
                    }
                    html.classList.remove('_active-head-nav');
                    document.documentElement.removeAttribute('style');
                } else {
                    if (typeof mainNav[0] !== 'undefined') {
                        if(window.innerWidth < this.maxTabletLandscapeWidth) {
                            const head = document.querySelector('header.head');
                            const topVal = head.getBoundingClientRect().height + 'px';

                            if(head && topVal) {
                                (<HTMLElement>mainNav[0]).style.top = topVal;
                                (<HTMLElement>mainNav[0]).style.height = 'calc(100% - '+topVal+')';
                                setTimeout(() => {
                                    (<HTMLElement>mainNav[0]).scrollTop = 0;
                                }, 10);
                                document.documentElement.style.overflow = 'hidden';
                            }
                        }
                        (<HTMLElement>mainNav[0]).style.display = 'block';
                    }
                    if (typeof toggleIcon[0] !== 'undefined') {
                        toggleIcon[0]?.classList.remove('uil-bars')
                        toggleIcon[0]?.classList.add('uil-times')
                    }
                    if (typeof toggleNav[0] !== 'undefined') {
                        toggleNav[0]?.classList.add('_active');
                    }
                    html?.classList.add('_active-head-nav');
                }
            });
        });
    }

    toggleMobileBack() {
        let container           = document.querySelectorAll('.head-meganav')[0];
        let level2Back = document.querySelectorAll('.head-nav-back-lvl2');
        let level3Back = document.querySelectorAll('.head-nav-back-lvl3');
        let level1Navs = document.querySelectorAll('.head-nav-lvl1 > ul > li');
        let level2Navs = document.querySelectorAll('.head-nav-lvl2');
        let level3Navs = document.querySelectorAll('.head-nav-lvl3-wrapper');

        level2Back = [].slice.call(level2Back);
        level3Back = [].slice.call(level3Back);
        level1Navs = [].slice.call(level1Navs);
        level2Navs = [].slice.call(level2Navs);
        level3Navs = [].slice.call(level3Navs);

        if(typeof container !== 'undefined') {
            level2Back.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        level1Navs.forEach((item: HTMLElement) => {
                            item.classList.remove('_active');
                        });

                        setTimeout(() => {
                            container.setAttribute('style', 'left: 100%;');
                        }, 0);
                    }
                });
            });

            level3Back.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        let parent = traversal.parents(item, '.head-nav-lvl3-wrapper')[0];

                        parent.classList.remove('_active');
                        level2Navs.forEach((l2nav: HTMLElement) => {
                            l2nav.classList.remove('_active');
                        });

                        setTimeout(() => {
                            parent.setAttribute('style', 'left: 100%;');
                        }, 0);
                    }
                });
            });
        }
    }

    toggle2ndLevel() {
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let level1Navs          = document.querySelectorAll('.head-nav-lvl1 > ul > li');
        let container           = document.querySelectorAll('.head-meganav')[0];
        level1Navs = [].slice.call(level1Navs);

        // share lots of same code with toggleMegaNav!!!
        if(typeof level1Nav !== 'undefined') {
            let hassub = level1Nav.querySelectorAll('.lvl1-hassub > a');
            let meganavContent = level1Nav.querySelectorAll('.meganav-content');
            let lvl2 = document.querySelectorAll('.head-nav-lvl2');
            let lvl3 = document.querySelectorAll('.head-nav-lvl3-wrapper');

            level1Navs.forEach((item: HTMLElement) => {
                item.addEventListener('click', (e) => {
                    if (this.maxTabletLandscapeWidthQuery.matches) {
                        if (item.classList.contains('lvl1-hassub')) {
                            let navId = item.getAttribute('data-nav-id');
                            let activeLvl2 = level1Nav.querySelectorAll('.meganav-' + navId)[0];

                            // reset active state of previous link
                            hassub.forEach((item: HTMLElement) => {
                                item.classList.remove('_active');
                            });

                            // add active state to selected link
                            item.classList.add('_active');

                            // close previous meganav content
                            meganavContent.forEach((content: HTMLElement) => {
                                content.setAttribute('style', 'display:none;');
                            });

                            // display meganav content
                            lvl2.forEach((item: HTMLElement) => {
                                item.classList.remove('_active');
                            });

                            activeLvl2.setAttribute('style', 'display:block;');
                            if(navId !== '0') {
                                activeLvl2.querySelectorAll('.head-nav-lvl2.hassub > a').forEach((anker: HTMLElement) => {
                                    anker.removeAttribute('href');
                                });
                            }
                            lvl3.forEach((item: HTMLElement) => {
                                // item.setAttribute('style', 'display:none;');
                                item.classList.remove('_active');
                            });

                            setTimeout(() => {
                                container.setAttribute('style', 'left: 0;');
                            }, 0);
                            item.classList.add('_active');
                            e.preventDefault();

                        }
                    }

                });
            });
        }
    }

    toggle3ndLevel() {
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let level2Navs          = document.querySelectorAll('.head-nav-lvl2');
        let level3Navs          = document.querySelectorAll('.head-nav-lvl3-wrapper');
        level3Navs      = [].slice.call(level3Navs);
        level2Navs      = [].slice.call(level2Navs);

        // share lots of same code with toggleMegaNav!!!
        if(typeof level1Nav !== 'undefined') {
            level2Navs.forEach((item: HTMLElement) => {
                if(item.querySelector('a')) {
                    let link = item.querySelectorAll('a')[0];
                    link.addEventListener('click', (e) => {
                        if (this.maxTabletLandscapeWidthQuery.matches) {
                            if (item.classList.contains('hassub')) {
                                const activeLvl3 = item.querySelector('.head-nav-lvl3-wrapper');
                                // reset active state of previous link
                                level2Navs.forEach((item2: HTMLElement) => {
                                    item2.classList.remove('_active');
                                });
                                // reset active state of previous link
                                level3Navs.forEach((item3: HTMLElement) => {
                                    item3.classList.remove('_active');
                                });

                                // add active state to selected link
                                item.classList.add('_active');
                                activeLvl3.classList.add('_active');

                                setTimeout(() => {
                                    activeLvl3.setAttribute('style', 'left: 0;');
                                }, 0);
                                e.preventDefault();
                            }
                        }
                    });
                }
            });
        }
    }

    toggleMegaNav() {
        let container           = document.querySelectorAll('.head-meganav')[0];
        let level1Nav           = document.querySelectorAll('.head-nav-lvl1')[0];
        let meganavContent: NodeListOf<HTMLElement>;
        let lvl2: NodeListOf<HTMLElement>;
        let lvl3: NodeListOf<HTMLElement>;
        let timeout: number;

        // continue if menu exist
        if(typeof level1Nav !== 'undefined') {
            let hassub = level1Nav?.querySelectorAll('.lvl1-hassub');
            meganavContent = level1Nav?.querySelectorAll('.meganav-content');
            let nosub = level1Nav?.querySelectorAll('.lvl1-nosub');
            lvl2 = document.querySelectorAll('.head-nav-lvl2');
            lvl3 = document.querySelectorAll('.head-nav-lvl3-wrapper');
            let delay: number;
            hassub = [].slice.call(hassub);
            meganavContent = [].slice.call(meganavContent);
            nosub = [].slice.call(nosub);
            lvl2 = [].slice.call(lvl2);
            lvl3 = [].slice.call(lvl3);

            // close & reset meganav onleave
            if (this.minDesktopWidthQuery.matches) {
                level1Nav.addEventListener('mouseleave', () => {

                    fadeOut(this.background);
                    container?.removeAttribute('style');
                    container?.classList.remove('_active');
                    container?.classList.remove('_in');
                    (document.querySelector('.head-nav-lvl1') as HTMLElement)?.removeAttribute('style');

                    clearTimeout(timeout);
                    clearTimeout(delay);

                    hassub.forEach((item: HTMLElement) => {
                        item?.classList.remove('_active');
                    });

                });

                level1Nav.addEventListener('focusout', (event) => {

                    const focusEvent = event as FocusEvent;

                    if (!level1Nav.contains(focusEvent.relatedTarget as Node) && !container.contains(focusEvent.relatedTarget as Node)) {
                        // Fokus hat die Navigation verlassen!
                        fadeOut(this.background);
                        container?.removeAttribute('style');
                        container?.classList.remove('_active');
                        container?.classList.remove('_in');
                        (document.querySelector('.head-nav-lvl1') as HTMLElement)?.removeAttribute('style');

                        clearTimeout(timeout);
                        clearTimeout(delay);

                        hassub.forEach((item: HTMLElement) => {
                            item.classList.remove('_active');
                        });
                    }
                });

                // close & reset meganav if hovering elements without sub
                nosub.forEach((item: HTMLElement) => {
                    item.addEventListener('mouseover', () => {

                        fadeOut(this.background);
                        container?.removeAttribute('style');
                        container?.classList.remove('_active');
                        container?.classList.remove('_in');
                        (document.querySelector('.head-nav-lvl1') as HTMLElement).removeAttribute('style');

                        clearTimeout(timeout);
                        clearTimeout(delay);

                        hassub.forEach((item: HTMLElement) => {
                            item.classList?.remove('_active');
                        });

                    });
                    item.addEventListener('focusin', () => {

                        fadeOut(this.background);
                        container?.removeAttribute('style');
                        container?.classList.remove('_active');
                        container?.classList.remove('_in');
                        (document.querySelector('.head-nav-lvl1') as HTMLElement).removeAttribute('style');

                        clearTimeout(timeout);
                        clearTimeout(delay);

                        hassub.forEach((item: HTMLElement) => {
                            item.classList.remove('_active');
                        });
                        meganavContent.forEach((content: HTMLElement) => {
                            content?.setAttribute('style', 'display:none;');
                        });

                    });
                });

                // open meganav
                hassub.forEach((item: HTMLElement) => {
                    item.addEventListener('mouseover', () => {

                        let navId = item?.getAttribute('data-nav-id');
                        let activeLvl2 = level1Nav?.querySelectorAll('.meganav-' + navId)?.[0];

                        clearTimeout(delay);

                        this.background.style.display = 'block';
                        setTimeout(() => {
                            this.background.style.opacity = '1';
                            this.background.style.transitionDelay = '.3s';
                        }, 10);

                        if (!container.classList.contains('_active')) {
                            toggleNavItems(activeLvl2);
                            item?.classList.add('_active');
                        } else {
                            delay = window.setTimeout(() => {
                                // reset active state of previous link
                                hassub.forEach((item: HTMLElement) => {
                                    item?.classList.remove('_active');
                                });
                                item?.classList.add('_active');
                                toggleNavItems(activeLvl2);
                            }, 350);
                        }
                    });

                    item.addEventListener('focusin', () => {

                        let navId = item.getAttribute('data-nav-id');
                        let activeLvl2:HTMLElement = level1Nav?.querySelectorAll('.meganav-' + navId)?.[0] as HTMLElement;

                        item?.setAttribute('aria-expanded', 'true');

                        clearTimeout(delay);

                        this.background.style.display = 'block';
                        setTimeout(() => {
                            this.background.style.opacity = '1';
                            this.background.style.transitionDelay = '.3s';
                        }, 10);

                        if (!container.classList.contains('_active')) {
                            toggleNavItems(activeLvl2);
                            item?.classList.add('_active');
                        } else {
                            delay = window.setTimeout(() => {
                                // reset active state of previous link
                                hassub.forEach((item: HTMLElement) => {
                                    item?.classList.remove('_active');
                                    item?.setAttribute('aria-expanded', 'false');
                                });
                                item?.classList.add('_active');
                                toggleNavItems(activeLvl2);
                            }, 350);
                        }
                    });
                });
            }

            document.addEventListener('keydown', (e: KeyboardEvent) => {

                const focusedElement = document.activeElement as HTMLElement;
                const isInMegaMenu = focusedElement?.closest('.head-nav');

                // Wenn Focus im Untermenu ist, dann verhindere das im Hintergrund body gescrollt wird.
                if (isInMegaMenu) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }

                if (focusedElement.closest('.meganav-content')) { // Wenn der Fokus auf einem Untermenü-Link liegt

                    if (e.key === 'ArrowDown') {
                        // Wenn bereits ein Link im Megamenu fokussiert ist, zum nächsten Link wechseln.
                        const nextLink :HTMLElement | null = focusedElement?.parentElement?.nextElementSibling?.querySelector('a');

                        if (nextLink) {
                            nextLink?.focus();
                        } else {
                            const nextMenu :Element | null = focusedElement?.closest('.head-nav-lvl2')?.nextElementSibling;
                            const nextLinkWrapper :HTMLElement | null = nextMenu?.querySelector('.head-nav-lvl3-wrapper');

                            // Fokus auf das erste Element im nächsten Mega-Menü setzen oder wenn letzter Menu ist, dann Hauptmenu fokussieren
                            if (nextLinkWrapper) {

                                const hasDirectAnchor = Array.from(nextLinkWrapper.children).some(child => child.tagName === 'A');

                                if (hasDirectAnchor) {
                                    const NextLinkWithoutSub :HTMLElement | null  = nextMenu?.querySelector('.head-nav-lvl3-wrapper a');
                                    NextLinkWithoutSub?.focus();
                                } else {
                                    const NextLinkWithSub :HTMLElement | null  = nextMenu?.querySelector('.head-nav-lvl3 a');
                                    NextLinkWithSub?.focus();
                                }
                            } else {
                                // Hauptmenu fokussieren
                                const navId = focusedElement?.closest('.meganav-content')?.getAttribute('data-nav-id');
                                const currentMenu :HTMLElement | null = document.querySelector(`.lvl1-hassub[data-nav-id="${navId}"] a`);
                                currentMenu?.focus();
                            }
                        }

                    } else if (e.key === 'ArrowUp') {
                        // Wenn bereits ein Link im Megamenu fokussiert ist, zum vorherige Link wechseln.
                        const prevLink :HTMLElement | null = focusedElement?.parentElement?.previousElementSibling?.querySelector('a');
                        if (prevLink) {
                            prevLink?.focus();
                        } else {
                            const prevMenu :Element | null = focusedElement?.closest('.head-nav-lvl2')?.previousElementSibling;
                            const prevLinkWrapper :HTMLElement | null = prevMenu?.querySelector('.head-nav-lvl3-wrapper');

                            // Fokus auf das letzte Element im vorherigen Mega-Menü setzen oder wenn erster Menu ist, dann auf Hauptmenu fokussieren
                            if (prevLinkWrapper) {

                                const hasDirectAnchor = Array.from(prevLinkWrapper.children).some(child => child.tagName === 'A');

                                if (hasDirectAnchor) {
                                    const prevLinkWithoutSub :HTMLElement | null = prevMenu?.querySelector('.head-nav-lvl3-wrapper a');
                                    prevLinkWithoutSub?.focus();
                                } else {
                                    const prevLinkWithSub :HTMLElement | null = prevMenu?.querySelector('.head-nav-lvl3');
                                    const lastAnchor  = prevLinkWithSub?.querySelectorAll('a:last-of-type')[prevLinkWithSub?.querySelectorAll('a')?.length - 1] as HTMLElement;
                                    lastAnchor?.focus();
                                }
                            } else {
                                // Hauptmenu fokussieren
                                const navId = focusedElement?.closest('.meganav-content')?.getAttribute('data-nav-id');
                                const currentMenu :HTMLElement | null = document.querySelector(`.lvl1-hassub[data-nav-id="${navId}"] a`);
                                currentMenu?.focus();
                            }
                        }
                    } else if (e.key === 'Escape' || e.key === 'Tab') {
                        // hauptmenu fokussieren
                        const navId = focusedElement?.closest('.meganav-content')?.getAttribute('data-nav-id');
                        const currentMenu :HTMLElement | null = document.querySelector(`.lvl1-hassub[data-nav-id="${navId}"] a`);
                        currentMenu?.focus();
                    }
                } else if (focusedElement?.closest('.lvl1-hassub')) { // Wenn der Fokus auf einem Hauptmenü liegt

                    if (e.key === 'ArrowDown' || e.code === 'Space') {
                        // Fokus auf den ersten Link im zugehörigen Untermenu setzen
                        e.preventDefault();
                        const navId = focusedElement?.closest('.lvl1-hassub')?.getAttribute('data-nav-id');
                        const megaMenu :HTMLElement | null = document.querySelector(`.meganav-${navId}`);
                        const nextLinkWrapper :HTMLElement | null = megaMenu?.querySelector('.head-nav-lvl3-wrapper');

                        if (megaMenu) {

                            const hasDirectAnchor = Array.from(nextLinkWrapper.children)?.some(child => child?.tagName === 'A');

                            if (hasDirectAnchor) {
                                const nextLinkWithoutSub :HTMLElement | null = megaMenu?.querySelector('.head-nav-lvl3-wrapper a');
                                nextLinkWithoutSub?.focus();
                            } else {
                                const nextLinkWithSub :HTMLElement | null = megaMenu?.querySelector('.head-nav-lvl3 a');
                                nextLinkWithSub?.focus();
                            }
                        }
                    }
                }
            });
        }

        function toggleNavItems(activeLvl2: Element) {
            // close previous meganav content

            meganavContent.forEach((content: HTMLElement) => {
                content.setAttribute('style', 'display:none;');
            });

            // display meganav content
            lvl2.forEach((item: HTMLElement) => {
                item.classList.remove('_active');
            });
            activeLvl2.setAttribute('style', 'display:flex;');
            container.scrollTop = 0;
            lvl3.forEach((item: HTMLElement) => {
                item.classList.remove('_active');
            });

            (document.querySelector('.head-nav-lvl1') as HTMLElement).style.setProperty('display', 'block', 'important');

            let offsetHeight = (document.querySelector('.head-wrapper') as HTMLElement).offsetHeight + 24 + 'px';
            offsetHeight = 'calc(100vh - ' + offsetHeight + ')';
            // if meganav not already active, slide down
            if (!container.classList.contains('_active')) {
                container.classList.add('_active');
                timeout = window.setTimeout(() => {
                    container.classList.add('_in');
                }, 250);
            }
            setTimeout(() => {
                (<HTMLElement>container).style.maxHeight = offsetHeight;
            }, 0);
        }
    }
    stateReset() {
        const html = document.getElementsByTagName('html')[0];
        let level1Nav = document.querySelectorAll('.head-nav-lvl1');
        level1Nav = [].slice.call(level1Nav);

        if(typeof level1Nav !== 'undefined' && level1Nav.length > 0) {
            let toggleNav = document.querySelectorAll('.toggle-head-nav');
            let toggleIcon = document.querySelectorAll('.toggle-head-nav i');
            toggleNav = [].slice.call(toggleNav);
            toggleIcon = [].slice.call(toggleIcon);

            window.addEventListener('resize', () => {
                if (this.maxTabletLandscapeWidthQuery.matches) {
                    level1Nav[0].setAttribute('style', 'display: none;');
                    toggleIcon[0].classList.remove('uil-times')
                    toggleIcon[0].classList.add('uil-bars')
                    toggleNav[0].classList.remove('_active');
                } else {
                    level1Nav[0].setAttribute('style', '');
                    html.classList.remove('_active-head-nav');
                }
            });
        }
    }

    init() {
        if(document.querySelector('.head-wrapper')) {
            this.toggleMobile();
            this.toggleMobileBack();
            this.stateReset();
            this.toggle2ndLevel();
            this.toggle3ndLevel();
            this.toggleMegaNav();
        }
    }
}
