import {fadeOut, fadeIn} from "../../utilities/helpers";
import {windowLoaded} from '../../utilities/domready';
import {toCart} from "../product/tocart";

export class modals {
    closeModals() {

        // modal close buttons
        const modalToggles = Array.from(document.querySelectorAll('.toggle-modal-close, .toggle-mdl-close'));
        modalToggles.forEach(toggle => {

            toggle.addEventListener('click', (e) => {
                e.preventDefault();
                const thisMdl: HTMLElement = toggle.closest('.mdl');
                const thisModal: HTMLElement = toggle.closest('.modal');
                const modals = document.querySelectorAll('.mdl, .modal');
                let activeModals = 0;
                modals.forEach((modal: HTMLElement) => {
                    modal.style.display === 'block' && activeModals++;
                });
                activeModals === 1 && fadeOut(document.querySelector('.modal-background'));
                if (thisMdl && thisMdl.id === 'modal-address-suggestions') {
                  thisMdl.style.display = 'none';
                } else {
                  if (thisMdl) {
                    fadeOut(thisMdl);
                  } else {
                    fadeOut(thisModal);
                  }
                }
            })


        })

        document.addEventListener('keydown', (e :KeyboardEvent) => {

            if(e.key === 'Escape') {

                const b2cSwitchModal:HTMLElement | null = document.querySelector('#modal-b2c-switch');
                const b2bSwitchModal:HTMLElement | null = document.querySelector('#modal-b2b-switch');

                if(b2cSwitchModal?.contains(document.activeElement) || b2bSwitchModal?.contains(document.activeElement)) {

                    const thisMdl: HTMLElement = document.activeElement.closest('.mdl');
                    const thisModal: HTMLElement = document.activeElement.closest('.modal');
                    const modals = document.querySelectorAll('.mdl, .modal');
                    let activeModals = 0;
                    modals.forEach((modal: HTMLElement) => {
                        modal.style.display === 'block' && activeModals++;
                    });
                    fadeOut(document.querySelector('.modal-background'));
                    if (thisMdl && thisMdl.id === 'modal-address-suggestions') {
                        thisMdl.style.display = 'none';
                    } else {
                        if (thisMdl) {
                            fadeOut(thisMdl);
                        } else {
                            fadeOut(thisModal);
                        }
                    }
                }
            }
        })

        // global modal close listener
        function fadeOutModals(target?: HTMLElement) {
            fadeOut(document.querySelector('.modal-background'));
            if (target) fadeOut(target);
            if(document.querySelector('#modal-addedtobasket')) {
                fadeOut(document.querySelector('#modal-addedtobasket'));
            }
            if(document.querySelector('#modal-wishlist')) {
                fadeOut(document.querySelector('#modal-wishlist'));
            }
            if(document.querySelector('#modal-reminder')) {
                fadeOut(document.querySelector('#modal-reminder'));
            }
            if(document.querySelector('#modal-energylabel')) {
                fadeOut(document.querySelector('#modal-energylabel'));
            }
            if(document.querySelector('#modal-accessory')) {
                fadeOut(document.querySelector('#modal-accessory'));
            }
            if(document.querySelector('#modal-b2c-switch')) {
                fadeOut(document.querySelector('#modal-b2c-switch'));
            }
            if(document.querySelector('#modal-b2b-switch')) {
                fadeOut(document.querySelector('#modal-b2b-switch'));
            }
            if(document.querySelector('#pda-lightbox-outer')) {
                fadeOut(document.querySelector('#pda-lightbox-outer'));
            }
            if(document.querySelector('#modal-sharing')) {
                fadeOut(document.querySelector('#modal-sharing'));
            }
        }
        document.querySelectorAll('.modal-close, .btn-modal-close, .cart-mdl-close').forEach((modal) => {
            modal.addEventListener('click', () => {
                event.preventDefault();
                fadeOutModals();
            });
        });
        document.querySelectorAll('.cart-accessory-close').forEach((modal) => {
            modal.addEventListener('click', () => {
                event.preventDefault();
                if(modal.classList.contains('reload')) {
                    window.location.reload();
                } else {
                    fadeOutModals();
                }
            });
        });
        document.addEventListener('click', (e) => {
            if(e.target && ((e.target as HTMLElement).classList.contains('modal-background') || (e.target as HTMLElement).classList.contains('mdl-wrap') || (e.target as HTMLElement).classList.contains('mdl-background'))) {
                fadeOutModals();
            }
        });
    }

    profileSwitch() {

        const ident = document.querySelector('input[name="ident"]') as HTMLInputElement;
        const profile = document.querySelector('input[name="profile"]') as HTMLInputElement;

        if (ident && ident.value === 'true') {
            const b2cToggles = document.querySelectorAll('.toggle-b2c-switch');
            const b2bToggles = document.querySelectorAll('.toggle-b2b-switch');

            b2cToggles && b2cToggles.forEach(btn => {
                if(!btn.classList.contains('form-field-input-checkbox')) {
                    btn.addEventListener('click', (event) => {
                        event.preventDefault();
                        if (profile && profile.value === 'B2B') {
                            fadeIn(document.querySelector('.modal-background'));
                            fadeIn(document.querySelector('#modal-b2c-switch'));
                            setTimeout(() => {
                                (document.querySelector('#modal-b2c-switch a') as HTMLElement)?.focus();
                            }, 100)

                        }
                    });
                }
            })

            b2bToggles && b2bToggles.forEach(btn => {
                if(!btn.classList.contains('form-field-input-checkbox')) {
                    btn.addEventListener('click', (event) => {
                        event.preventDefault();
                        if (profile && profile.value === 'B2C') {
                            fadeIn(document.querySelector('.modal-background'));
                            fadeIn(document.querySelector('#modal-b2b-switch'));
                            setTimeout(() => {
                                (document.querySelector('#modal-b2b-switch a') as HTMLElement)?.focus();
                            }, 100)
                        }
                    });
                }
            })
        }
    }

    clickToCopy() {
      const inputField = document.querySelector('.input-base.has-button');
      if (!inputField) return;

      const copyButton: HTMLButtonElement = inputField.querySelector('.cta-copy');
      const input = inputField.querySelector('input');

      const copyContent = async () => {
        try {
          await navigator.clipboard.writeText(input.value);
          input.select();
          input.setSelectionRange(0, 99999);
          copyButton.classList.remove('cta-secondary');
          copyButton.disabled = true;
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }

      copyButton && copyButton.addEventListener('click', copyContent);
    }

    init() {
        windowLoaded(() => {
            this.closeModals();
            this.profileSwitch();
            this.clickToCopy();
        });
    }
}
