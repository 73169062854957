/*
 *  Workaround to get i18n translations that have been saved within an HTML div (.i18n-properties-splide)
 */
const getSplideTranslations = () => {
    return {
        next : document.querySelector<HTMLElement>('.i18n-properties-splide > .splide-next')?.innerText ?? 'Nächste',
        prev : document.querySelector<HTMLElement>('.i18n-properties-splide > .splide-prev')?.innerText ?? 'Vorherige',
        first : document.querySelector<HTMLElement>('.i18n-properties-splide > .splide-first')?.innerText ?? 'Erste',
        last : document.querySelector<HTMLElement>('.i18n-properties-splide > .splide-last')?.innerText ?? 'Letzte',
    };
}
/**
 * Updates the aria label (only if it is different from the expected value.)
 */
const updateAriaLabel = (element: Element, label: string) => {
    const currentLabel = element.getAttribute('aria-label');
    if (currentLabel !== label) {
        element.setAttribute('aria-label', label);
    }
}
/**
 * Applies the i18n aria-label translations to slider elements
 */
const applyAriaLabelsToSlider = (slider: HTMLElement) => {
    const { next, prev, first, last } = getSplideTranslations();

    const prevArrow = slider.querySelector('.splide__arrow--prev');
    if (prevArrow) updateAriaLabel(prevArrow, prev);

    const nextArrow = slider.querySelector('.splide__arrow--next');
    if (nextArrow) updateAriaLabel(nextArrow, next);

    const firstArrow = slider.querySelector('.splide__arrow--first');
    if (firstArrow) updateAriaLabel(firstArrow, first);

    const lastArrow = slider.querySelector('.splide__arrow--last');
    if (lastArrow) updateAriaLabel(lastArrow, last);
}
/**
 * Observes the DOM for dynamically added or updated Splide sliders to set aria labels
 */
const observeSplideInitialization = () => {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === "attributes" && mutation.attributeName === "class") {
                const target = mutation.target as HTMLElement;
                // Only trigger when `is-initialized` is set
                if (target.classList.contains("splide") && target.classList.contains("is-initialized")) {
                    applyAriaLabelsToSlider(target);
                }
            }
        });
    });

    // Observe the document for changes in child nodes and attributes
    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['class'],
    });
}

export const setupSplideAriaLabels = () =>{
    // Running initial setup for already initialized sliders
    document.querySelectorAll('.splide.is-initialized').forEach(applyAriaLabelsToSlider);
    // Observes DOM for dynamically added or updated sliders
    observeSplideInitialization();
}